export default {
  "name": "@account-abstraction/utils",
  "version": "0.5.0",
  "main": "./dist/src/index.js",
  "license": "MIT",
  "files": [
    "dist/*",
    "contracts/*",
    "README.md"
  ],
  "scripts": {
    "clear": "rm -rf dist artifacts cache src/types",
    "hardhat-compile": "hardhat compile",
    "lint-fix": "eslint -f unix . --fix",
    "watch-tsc": "tsc -w --preserveWatchOutput",
    "tsc": "tsc"
  },
  "dependencies": {
    "@account-abstraction/contracts": "^0.5.0",
    "@ethersproject/abi": "^5.7.0",
    "@ethersproject/providers": "^5.7.0",
    "@openzeppelin/contracts": "^4.7.3",
    "debug": "^4.3.4",
    "ethers": "^5.7.0"
  },
  "devDependencies": {
    "@nomicfoundation/hardhat-toolbox": "^1.0.2",
    "@nomiclabs/hardhat-ethers": "^2.0.0",
    "hardhat": "^2.11.0"
  },
  "gitHead": "366c7b65af9be8a31f18db939ee05ee298c086cb"
}
;